import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import ClassNames from 'classnames';
import componentStyles from './LinkList.module.scss';
import { FaExternalLinkAlt } from 'react-icons/fa';

const LinkList = ({ links, locale, numberedList, ...props }) => {
	const ListType = numberedList ? 'ol' : 'ul';

	return (
		<ListType
			className={numberedList ? ClassNames(componentStyles.linkList, 'largeNums') : componentStyles.linkList}
		>
			{links
				.filter((link) => !locale || locale === 'en' || (locale === 'fr' && !link.hideFrench))
				.map((link, i) => (
					<li key={i} className='centered'>
						<div>
							{link.internal && (
								<Link to={link.path} className={!link.description ? componentStyles.centered : ''}>
									<FaExternalLinkAlt className={componentStyles.icon} />
									<div>
										<span className={componentStyles.title}>{link.text}</span>
										{link.description && (
											<span className={componentStyles.description}>{link.description}</span>
										)}
										{link.subtitle && <span style={{ fontWeight: `bold` }}>{link.subtitle}</span>}
										{link.date && <span>{link.date}</span>}
									</div>
								</Link>
							)}

							{!link.internal && (
								<a href={link.path} className={!link.description ? componentStyles.centered : ''}>
									<FaExternalLinkAlt className={componentStyles.icon} />
									<div>
										<span className={componentStyles.title}>{link.text}</span>
										{link.description && (
											<span className={componentStyles.description}>{link.description}</span>
										)}
										{link.subtitle && <span style={{ fontWeight: `bold` }}>{link.subtitle}</span>}
									</div>
								</a>
							)}
						</div>
					</li>
				))}
		</ListType>
	);
};

export default LinkList;
